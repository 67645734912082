import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import { fullloader, toast } from '../../../../redux/action';
import MenuContent from '../MenuContent';
import Feature from '../../../../infrastructure/features';
import { useIsFeatureEnabled } from '../../../../libs/feature-toggles/react';
import BaseHeader from './BaseHeader';
import useAutosave from '../Autosave';
import { NextButton } from './Header.styled';
import {
  getAVersionId,
  createVersionBDraft,
  getIdOfAnotherVersion,
} from '../../../../redux/services';

const DraftHeader = ({
  proceedToNextPage,
  isDesignLoading,
  data = {},
  beeEditor,
  saveStatus,
  isTextStatusSaved,
  onUnsavedStatus,
  onSave,
  design,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const isABFeatureEnabled = useIsFeatureEnabled(Feature.AB);
  const { draft: draftId } = queryString.parse(location.search);
  const messageInfoRef = useRef(null);
  const [draftName, setDraftName] = useState(data.name || '');
  const [isABToggleEnabled, setABToggleEnabled] = useState(data?.AB);
  const [
    isConfirmDeleteABDraftModalVisible,
    setConfirmDeleteABDraftModalVisible,
  ] = useState(false);
  const [activeVersion, setActiveVersion] = useState(
    data?.AB && data.AB?.version
  );

  useEffect(() => {
    if (data.name) {
      setDraftName(data.name);
      setABToggleEnabled(data.AB);
    }
  }, [data.name]);

  const saveDraftName = async () => {
    await onSave({ name: draftName });
  };

  useAutosave({
    dependancyList: [draftName],
    saveFunc: saveDraftName,
    onUnsavedStatus,
  });

  const proceedToAudience = async () => {
    dispatch(fullloader(false));

    const versionAData =
      isABFeatureEnabled && data.AB ? await getAVersionId(draftId) : null;
    const idOfAVersionDraft = versionAData?._id;

    history.push(`/dashboard/audience?draft=${idOfAVersionDraft || draftId}`);
  };

  const continueToAudience = async () => {
    const isValid = await messageInfoRef.current.validate();
    if (isValid) {
      try {
        dispatch(fullloader(true, 'Proceeding to Audience Page'));
        await beeEditor.save();
        proceedToNextPage.current = proceedToAudience;
      } catch (error) {
        dispatch(
          toast('error', 'Draft saving error while proceeding to audience')
        );
      }
    }
  };

  const changeVersion = async () => {
    const { _id } = await getIdOfAnotherVersion(draftId);

    history.replace(`/dashboard/sendmessage?draft=${_id}`);
  };

  const handleVersionChange = async version => {
    // if draft has not been created before creating an AB draft
    // then create a new draft first and then AB feature
    if (draftId === undefined) {
      // this function creates new draft
      await onSave();
      // need to return because otherwise draftId is still undefined
      // and AB draft cannot be created
      return;
    }
    // if no active version is set, default to version A
    if (!activeVersion) {
      setActiveVersion('A');
      await createVersionBDraft(draftId);
    }
    dispatch(fullloader(true, 'Switching versions'));

    setActiveVersion(version);
    // we need to save the draft so that html file is up to date
    // and there are no issues in preview screen
    await beeEditor.save();
    // this is for switching between the versions
    // we need this to make sure that the previous version is saved first
    // before switching to the other version
    proceedToNextPage.current = changeVersion;
  };

  const menu = (
    <MenuContent
      shouldIncludeABToggle
      initialName={draftName}
      isABToggleEnabled={isABToggleEnabled}
      setABToggleEnabled={setABToggleEnabled}
      setConfirmDeleteABDraftModalVisible={setConfirmDeleteABDraftModalVisible}
      handleVersionChange={handleVersionChange}
      beeEditor={beeEditor}
      isBeeLoading={isDesignLoading}
    />
  );

  const canProceedToNextPage = !isDesignLoading && isTextStatusSaved;

  const nextButton = (
    <NextButton
      onClick={() => (canProceedToNextPage ? continueToAudience() : null)}
      disabled={!canProceedToNextPage}
    >
      <PeopleOutlineIcon /> Audience <ArrowForwardOutlinedIcon />
    </NextButton>
  );

  return (
    <BaseHeader
      beeEditor={beeEditor}
      menu={menu}
      nextButton={nextButton}
      backBtnTargetUrl="/dashboard/drafts"
      nameInputPlaceholder="Enter Draft Name"
      messageInfoData={data}
      saveStatus={saveStatus}
      onUnsavedStatus={onUnsavedStatus}
      onSave={onSave}
      messageInfoRef={messageInfoRef}
      onNameChange={setDraftName}
      name={draftName}
      isDesignLoading={isDesignLoading}
      isABToggleEnabled={isABToggleEnabled}
      setABToggleEnabled={setABToggleEnabled}
      isConfirmDeleteABDraftModalVisible={isConfirmDeleteABDraftModalVisible}
      setConfirmDeleteABDraftModalVisible={setConfirmDeleteABDraftModalVisible}
      handleVersionChange={handleVersionChange}
      design={design}
    />
  );
};

export default DraftHeader;
